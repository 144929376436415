import Layout3 from '@/components/layouts/Layout3.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import SiteHeader from '@/components/SiteHeader.vue';
export default defineComponent({
  components: {
    SiteFooter: SiteFooter,
    SiteHeader: SiteHeader,
    Layout3: Layout3
  },
  setup: function setup() {
    var _storeToRefs = storeToRefs(useUserStore()),
      userId = _storeToRefs.id;
    var _storeToRefs2 = storeToRefs(useDirectSignStore()),
      directSignSession = _storeToRefs2.directSignSession;
    var _useWidgetStore = useWidgetStore(),
      resetWidgets = _useWidgetStore.resetWidgets;
    onBeforeMount(resetWidgets);
    return {
      userId: userId,
      directSignSession: directSignSession
    };
  },
  head: function head() {
    return {
      title: 'Sorry'
    };
  }
});